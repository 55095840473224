import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility";

export const SUBMIT_MIGRATE_EXISTING_USER_TO_AAD_DATA = "components/ValidateAADLogin/SUBMIT_MIGRATE_EXISTING_USER_TO_AAD_DATA";
export const submitMigrateExistingUserToAADData = makeActionCreator(SUBMIT_MIGRATE_EXISTING_USER_TO_AAD_DATA, false, "apiaadMigrationData");

export const SET_MIGRATE_EXISTING_USER_TO_AAD_RESULT = "components/ValidateAADLogin/SET_MIGRATE_EXISTING_USER_TO_AAD_RESULT";
export const setMigrateExistingUserToAADResult = makeActionCreator(SET_MIGRATE_EXISTING_USER_TO_AAD_RESULT, false, "migrateExistingUserToAADResult");

export const CHECK_IS_TID_MSA_ACCOUNT = "COMMON/ANNOUNCEMENT/CHECK_IS_TID_MSA_ACCOUNT";
export const CheckIsTidMSAAccount = makeActionCreator(CHECK_IS_TID_MSA_ACCOUNT, false, "UserInfoData");

export const SET_ISLOGIN_USER_MSAUSER = "components/ValidateAADLogin/SET_ISLOGIN_USER_MSAUSER";
export const setIsLoginUserMSAUserResult = makeActionCreator(SET_ISLOGIN_USER_MSAUSER, false, "msaDetails");
