import ApplicationConfig from "@ec-oem/ec.oem.oa3.ui.common/utility/applicationConfig";
import { take, put, select, call, apply } from "redux-saga/effects";
import { throwException } from "@ec-oem/ec.oem.oa3.ui.common/components/Logger/Logger.actions";
import {
	GET_CHATGPT_RESPONSE,
	addMessages,
	ADD_TO_MESSAGEITEM_LIST,
	setCount,
	setList,
	addMessageItem,
	setConversationId,
	GET_COSMIC_RESPONSE,
	SUBMIT_FEEDBACK,
	setIsResponseStopEnabled,
	GET_KEY_CATEGORIES_LIST,
	setKeyCategoriesList,
	setShowStreamingPlaceholder,
	setStreamingContent
} from "./ChatBot.actions";
import { getMessages, getCount, getList, getConversationId, getAllMessages } from "./ChatBot.selectors";
import { InvokeUrl } from "@ec-oem/ec.oem.oa3.ui.common/utility";
import { appendList } from "./BotMapper";

import { showAlert } from "@ec-oem/ec.oem.oa3.ui.core/components";
import { AlertsTypes } from "@ec-oem/ec.oem.oa3.ui.core/constants";
import { ChatBotConstants, FeedbackModalConstants, FeedbackStatus, StreamingEndIndicator, OpenAIContentFilterErrorToken, EventChannelStreamingError, EventChannelStreamingErrorJsonMessage } from "../../constants/SettingConstant";
import { eventChannel} from 'redux-saga';

function BuildValidJsonForStreamedData(incompleteJSON, lastValidJson) {
    if (incompleteJSON.includes('"response":"')) {
        const isValidJson = (jsonString) => {
            try {
                let data = JSON.parse(jsonString);
            } catch (error) {
                return false;
            }
            return true;
        };

        if (isValidJson(incompleteJSON)) {
            lastValidJson = JSON.parse(incompleteJSON);
        }
        if (isValidJson(incompleteJSON + "}")) {
            lastValidJson = JSON.parse(incompleteJSON + "}");
        }
        if (isValidJson(incompleteJSON + '"}')) {
            lastValidJson = JSON.parse(incompleteJSON + '"}');
        }
    } else {
        return null;
    }

    return lastValidJson;
}

function createStreamingChannel(reader) {
	return eventChannel((emit) => {
		const decoder = new TextDecoder('utf-8');
		const readChunk = () => {
		reader.read().then(({ done, value }) => {
			if (done) {
				emit(StreamingEndIndicator);
			} else {
				emit(decoder.decode(value, { stream: true }));
				readChunk();
			}
		}).catch((err) => {
			emit(EventChannelStreamingError);
		});
		};

		readChunk();

		return () => {
			reader.cancel().catch(() => {});
		};
	});
}

export function* getChatgptResponseFunction() {
	while (true) {
		try {
			const { message, abortSignal } = yield take(GET_CHATGPT_RESPONSE);
			const config = yield ApplicationConfig.commonConfig;
			//const {message} = values

			let messages = yield select(getAllMessages);
			let c = yield select(getCount);
			let guid = yield select(getConversationId);

			if (guid == null || c === 2) {
				guid = generateGuid();
				yield put(setConversationId(guid));
			}

			messages = [
				...messages,
				{
					"SequenceId": c - 1,
					"MessageRole": 3,
					"MessageText": message,
					"RelevantQuery": "",
					"MessageType": ""
				}
			];

			yield put(addMessages(messages));

			// This will fetch the last 3 messages
			let messages1 = yield select(getAllMessages);

			const conversationList = {
				"ConversationList": messages1
			};

			let requestData = JSON.stringify(conversationList);

			const response = yield call(InvokeUrl, {
				Config: {
					url: `${config.OEMChatBot.ChatBotAPIUrl}/api/Chat/AnswerStream`,
					method: "POST",
					data: requestData,
					RequestHeaders: [
						{
							key: "ConversationId",
							value: `${guid}`
						}
					],
					signal: abortSignal
				},
				IsPanelTask: true,
				SagaHandleResponse: { HandleResponse: true },
				SagaHandleError: false
			});

			if (response.name == "AbortError") {
				let messages2 = yield select(getAllMessages);

				const lastElementIndex = messages2.length - 1;
				const lastElement = messages2[lastElementIndex];
				lastElement.RelevantQuery = lastElement.MessageText;
				lastElement.MessageType = "aborted";
				yield put(addMessages(messages2));
			} else if (response.status === 200 || response.status == 500) {
				const reader = response.body.getReader();
				const channel = yield call(createStreamingChannel, reader);
				
				var final = '';

				try {
					let StreamingPlaceholderDefaultContent = appendList("Generating Response....", ChatBotConstants.BOT_TITLE, ChatBotConstants.BOT_INITIALS, c, false, false, [], false, FeedbackStatus.NoFeedback);
					yield put(setStreamingContent(StreamingPlaceholderDefaultContent));
					yield put(setShowStreamingPlaceholder(true));
					
					let lastValidJson = null;
                    while (true) {
                        const chunk = yield take(channel);
                        if (chunk === StreamingEndIndicator) {
							break;
						}
						else if(chunk === EventChannelStreamingError){
							yield put(throwException("Stream error: Error while reading chatBot response."));
							final = JSON.stringify(EventChannelStreamingErrorJsonMessage);
							let obj = appendList(EventChannelStreamingErrorJsonMessage.response, ChatBotConstants.BOT_TITLE, ChatBotConstants.BOT_INITIALS, c, false, false, [], false, FeedbackStatus.NoFeedback);
							yield put(setStreamingContent(obj));
							break;
						}
						else if (chunk.includes(OpenAIContentFilterErrorToken)){
							yield put(throwException("Stream error: Received OpenAIContentFilterError Token - OpenAI Content filter is triggered"));
							final = chunk.substring(chunk.indexOf(":")+1);
							lastValidJson = BuildValidJsonForStreamedData(final, lastValidJson);
							if(lastValidJson!==null && lastValidJson.response!==null){
								let obj = appendList(lastValidJson.response, ChatBotConstants.BOT_TITLE, ChatBotConstants.BOT_INITIALS, c, false, false, [], false, FeedbackStatus.NoFeedback);
								yield put(setStreamingContent(obj));
							}
							break;
						}
						let buffer = 20;
						for (let i = 0; i < chunk.length; i+=buffer) {
							final += chunk.substring(i,i+buffer);
							lastValidJson = BuildValidJsonForStreamedData(final, lastValidJson)
							if(lastValidJson!==null && lastValidJson.response!==null){
								let obj = appendList(lastValidJson.response, ChatBotConstants.BOT_TITLE, ChatBotConstants.BOT_INITIALS, c, false, false, [], false, FeedbackStatus.NoFeedback);
								yield put(setStreamingContent(obj));
							}
						}
                    }

					yield put(setShowStreamingPlaceholder(false));
					yield put(setStreamingContent(null)); 
                } catch (error) {
                    yield put(throwException(`getChatgptResponse error: ${error.message}`));
					data = EventChannelStreamingErrorJsonMessage;
                }

				let data;
				try {
					data = JSON.parse(final);
				} catch (error) {
					yield put(throwException("getChatgptResponse error: Error while parsing JSON string"));
					data = EventChannelStreamingErrorJsonMessage;
				}

				if (data !== null) {
					let obj = appendList(data.response, ChatBotConstants.BOT_TITLE, ChatBotConstants.BOT_INITIALS, c, true, true, data.suggestedQuestions, data.isSupportAssistanceRequired, FeedbackStatus.NoFeedback);
					yield put(addMessageItem(obj));

					let messages2 = yield select(getAllMessages);
					let c2 = yield select(getCount);

					const lastElementIndex = messages2.length - 1;
					const lastElement = messages2[lastElementIndex];
					lastElement.RelevantQuery = data.relevantQuery;
					lastElement.MessageType = data.responseType;

					messages2 = [
						...messages2,
						{
							"SequenceId": c2,
							"MessageRole": 2,
							"MessageText": data.response,
							"RelevantQuery": "",
							"MessageType": data.responseType
						}
					];

					yield put(addMessages(messages2));
				}
			} else {
				yield put(
					showAlert({
						type: AlertsTypes.ERROR,
						message: ["Failed to fetch bot answer"]
					})
				);
			}
			yield put(setIsResponseStopEnabled(false));
		} catch (error) {
			yield put(throwException("getChatgptResponse error: " + error));
		}
	}
}

export function* GetKeyCategories() {
	while (true) {
		try {
			yield take(GET_KEY_CATEGORIES_LIST);
			const config = yield ApplicationConfig.commonConfig;

			let c = yield select(getCount);

			let guid = yield select(getConversationId);

			if (guid == null || c === 2) {
				guid = generateGuid();
				yield put(setConversationId(guid));
			}
			const response = yield call(InvokeUrl, {
				Config: {
					url: `${config.OEMChatBot.ChatBotAPIUrl}/api/Chat/GetKeyCategories`,
					method: "GET",
					RequestHeaders: [
						{
							key: "ConversationId",
							value: `${guid}`
						}
					]
				},
				IsPanelTask: true,
				SagaHandleResponse: { HandleResponse: true },
				SagaHandleError: false,
				IsbackGroundTask: true
			});

			if (response.status === 200) {
				let data = yield apply(response, response.json);
				    let keyCategories = data.keyCategories;
					keyCategories = keyCategories.map((category) => { return {...category, isSelected : false}});
					yield put(setKeyCategoriesList(data.keyCategories));
				}
			 else {
				yield put(throwException("Get Key Categories error response code: " + response.status));
			}
		} catch (error) {
			yield put(throwException("Get Key Categories error: " + error));
		}
	}
}

export function* addtoList() {
	while (true) {
		try {
			var values = yield take(ADD_TO_MESSAGEITEM_LIST);
			const { messageitem } = values;

			let list = yield select(getList);

			const lastElementIndex = list.length - 1;
			const lastElement = list[lastElementIndex];

			if (lastElement && (lastElement.cosmicPresentKey || lastElement.feedbackPresentKey || lastElement.suggestedQuestions)) {
				let obj = appendList(lastElement.commentText, ChatBotConstants.BOT_TITLE, ChatBotConstants.BOT_INITIALS, lastElementIndex, false, false, null, false, lastElement.feedbackStatus);
				obj.index = lastElementIndex;
				list[lastElementIndex] = obj;
				yield put(setList(list));
			}

			if (messageitem) {
				list = [...list, messageitem];
				yield put(setList(list));

				let c = yield select(getCount);
				yield put(setCount(c + 1));
			}
		} catch (error) {
			console.log(error);
		}
	}
}

//Generates guid for conversationId
function generateGuid() {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
		var r = (Math.random() * 16) | 0,
			// eslint-disable-next-line no-mixed-operators
			v = c === "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export function* createCosmicTicket() {
	while (true) {
		try {
			yield take(GET_COSMIC_RESPONSE);
			const config = yield ApplicationConfig.commonConfig;

			let c = yield select(getCount);

			let guid = yield select(getConversationId);

			if (guid == null || c === 2) {
				guid = generateGuid();
				yield put(setConversationId(guid));
			}

			// This will fetch all the messages
			let allmessages = yield select(getAllMessages);
			const conversationList = {
				"ConversationList": allmessages
			};
			let requestData = JSON.stringify(conversationList);

			//COSMIC API BELOW

			const response = yield call(InvokeUrl, {
				Config: {
					url: `${config.OEMChatBot.ChatBotAPIUrl}/api/Chat/CreateCase`,
					method: "POST",
					data: requestData,
					RequestHeaders: [
						{
							key: "ConversationId",
							value: `${guid}`
						}
					]
				},
				IsPanelTask: true,
				SagaHandleResponse: { HandleResponse: true },
				SagaHandleError: false
			});

			if (response.status === 200 || response.status == 500) {
				let data = yield apply(response, response.json);

				if (data !== null) {
					let obj = appendList(data.response, ChatBotConstants.BOT_TITLE, ChatBotConstants.BOT_INITIALS, c);
					yield put(addMessageItem(obj));

					let messages2 = yield select(getAllMessages);
					let c2 = yield select(getCount);

					messages2 = [
						...messages2,
						{
							"SequenceId": c2,
							"MessageRole": 2,
							"MessageText": data.response,
							"RelevantQuery": "",
							"MessageType": data.responseType
						}
					];

					yield put(addMessages(messages2));
				}
			} else {
				yield put(
					showAlert({
						type: AlertsTypes.ERROR,
						message: ["Failed to Create Cosmic Ticket"]
					})
				);
			}
		} catch (error) {
			yield put(throwException("Create Cosmic Ticket error: " + error));
		}
	}
}

export function* addFeedback() {
	while (true) {
		try {
			let { submitFeedbackRequest } = yield take(SUBMIT_FEEDBACK);
			const config = yield ApplicationConfig.commonConfig;

			let c = yield select(getCount);

			let guid = yield select(getConversationId);

			if (guid == null || c === 2) {
				guid = generateGuid();
				yield put(setConversationId(guid));
			}
			let requestData = FeedbackRequestMapper(submitFeedbackRequest, c - 1);
			const response = yield call(InvokeUrl, {
				Config: {
					url: `${config.OEMChatBot.ChatBotAPIUrl}/api/Chat/AddFeedback`,
					method: "POST",
					data: JSON.stringify(requestData),
					RequestHeaders: [
						{
							key: "ConversationId",
							value: `${guid}`
						}
					]
				},
				IsPanelTask: true,
				SagaHandleResponse: { HandleResponse: true },
				SagaHandleError: false
			});

			if (response.status === 200 || response.status == 500) {
				let data = yield apply(response, response.json);

				if (data !== null) {
					let list = yield select(getList);

					const lastElementIndex = list.length - 1;
					const lastElement = list[lastElementIndex];

					let feedbackValue = requestData.Upvote ? FeedbackStatus.PositveFeedback : FeedbackStatus.NegativeFeedback
					let lastObj = appendList(lastElement.commentText, ChatBotConstants.BOT_TITLE, ChatBotConstants.BOT_INITIALS, lastElementIndex, false, false, null, false, feedbackValue);
					list[lastElementIndex] = lastObj;
					yield put(setList(list));
					
		
					let obj = appendList(data.response, ChatBotConstants.BOT_TITLE, ChatBotConstants.BOT_INITIALS, c);
					yield put(addMessageItem(obj));

					let messages2 = yield select(getAllMessages);
					let c2 = yield select(getCount);

					messages2 = [
						...messages2,
						{
							"SequenceId": c2,
							"MessageRole": 2,
							"MessageText": data.response,
							"RelevantQuery": "",
							"MessageType": data.responseType
						}
					];

					yield put(addMessages(messages2));
				}
			} else {
				yield put(
					showAlert({
						type: AlertsTypes.ERROR,
						message: ["Failed to add feedback"]
					})
				);
			}
		} catch (error) {
			yield put(throwException("Add feedback error: " + error));
		}
	}
}
const FeedbackRequestMapper = (feedbackValues, sequenceNo) => {
	return {
		Experience: feedbackValues[FeedbackModalConstants.FEEDBACK_TEXT],
		ContactRegardingFeedback: feedbackValues[FeedbackModalConstants.CONTACT_FOR_FEEDBACK],
		Upvote: feedbackValues[FeedbackModalConstants.FEEDBACK_UPVOTE],
		InaccurateContent: feedbackValues[FeedbackModalConstants.FEEDBACK_INACCURATE],
		ContentLackingClarity: feedbackValues[FeedbackModalConstants.FEEDBACK_LACKING_CLARITY],
		OffensiveContent: feedbackValues[FeedbackModalConstants.FEEDBACK_OFFENSIVE],
		OtherFeedback: feedbackValues[FeedbackModalConstants.FEEDBACK_OTHER],
		ConversationSequenceNumber: sequenceNo
	};
};
